import { Box, Button } from '@mui/material';
import './App.css';
import backgroundImageScene from './assets/images/scene.png';
import harryGif from './assets/images/harry.gif';
import hermioneGif from './assets/images/hermione.gif';
import malfoyGif from './assets/images/malfoy.gif';
import ronGif from './assets/images/ron.gif';

export default function App() {

  return (<>

    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={backgroundImageScene} style={{ objectFit: 'cover' }} width={'100%'} height={'100%'} />
    </Box>

    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

      <Box sx={{ zIndex: 1, height: '40%' }}>
        <img src={ronGif} height={'100%'} />
        <img src={malfoyGif} height={'100%'} />
        <img src={harryGif} height={'100%'} />
        <img src={hermioneGif} height={'100%'} />
      </Box>

    </Box>

    <Box sx={{ zIndex: 2, position: 'absolute', bottom: '100px', left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Button 
        onClick={(e) => window.location.href = 'https://www.twitch.tv/townworld'} disableElevation disableRipple variant='contained' 
        sx={{ 
          transitionProperty: 'transform',
          transitionDuration: '0.2s',
          ":active": { transform: 'scale(0.92)' },
          ":hover": { backgroundColor: '#C38918' },
          fontFamily: 'Ubuntu', fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px', backgroundColor: '#C38918', textTransform: 'none', fontSize: '18px', borderRadius: '24px' 
        }} 
      >
        {'Watch on Twitch'}
      </Button>
    </Box>

  </>);
}
